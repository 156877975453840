import React from 'react'
import logo from '../../new-logo.png';

export default function header() {
	return (
		<>
			<nav className="navbar navbar-default" role="navigation">
				<div className="container">
					<div className="navbar-header">
						<button className="navbar-toggle" type="button" data-toggle="collapse" data-target=".navbar-ex1-collapse">
							<span className="sr-only">Toggle Nav</span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
						</button>
						<a href="/" className="navbar-brand">
							<img src={logo} alt=""/>
						</a>
					</div>
					<div className="navigation collapse navbar-collapse navbar-ex1-collapse">
						<ul className="nav navbar-nav">
							<li className="current"><a href="#intro">Home</a></li>
							<li><a href="#about">About</a></li>
							<li><a href="#services">Events</a></li>
							<li><a href="#team">Team</a></li>
							<li><a href="#bottom-widget">Contact</a></li>
						</ul>
					</div>
				</div>
			</nav>
		</>
	)
}
