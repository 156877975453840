import React, { Component } from 'react'

export default class footer extends Component {
    render() {
        return (
            <>
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <p>Copyright &copy; Lebnet. All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}
