import React, { Component } from 'react'

export default class bottomWidget extends Component {
    render() {
        return (
            <>
                <section id="bottom-widget" className="home-section bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="contact-widget wow bounceInLeft">
                                    <i className="fa fa-map-marker fa-4x"></i>
                                    <h5>Main Office</h5>
                                    <p>43 Rue Belcourt, <br />Dollard-des-Ormeaux QC H9A 1Y2, Canada</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="contact-widget wow bounceInLeft">
                                    <i className="fa fa-envelope fa-4x"></i>
                                    <h5>Email us</h5>
                                    <p><a href="mailto:info@lebnet.ca" target="_blank">info@lebnet.ca</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="row mar-top30">
                            <div className="col-md-12">
                                <h5>We're on social networks</h5>
                                <ul className="social-network">
                                    <li>
                                        <a href="https://www.facebook.com/LebNetOrg/" target="_blank" className="">
                                            <span className="fa-stack fa-2x">
                                                <i className="fa fa-circle fa-stack-2x"></i>
                                                <i className="fa fa-facebook fa-stack-1x fa-inverse"></i>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/lebnet/" target="_blank" className="">
                                            <span className="fa-stack fa-2x">
                                                <i className="fa fa-circle fa-stack-2x"></i>
                                                <i className="fa fa-linkedin fa-stack-1x fa-inverse"></i>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/LebNetOrg" target="_blank" className="">
                                            <span className="fa-stack fa-2x">
                                                <i className="fa fa-circle fa-stack-2x"></i>
                                                <i className="fa fa-twitter fa-stack-1x fa-inverse"></i>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
