import React, { Component } from 'react'
import img1 from '../../img/intro/LebNet-Montreal.jpeg'
import logo from '../../new-logo.png';

export default class banner extends Component {
    render() {
        return (
            <>
                <section id="intro">
                    <div className="intro-container">
                        <div id="introCarousel" className="carousel slide carousel-fade" data-ride="carousel">
                            <div className="carousel-inner" role="listbox">
                                <div className="item active">
                                    <div className="carousel-background">
                                            <img src={img1} alt="" />
                                    </div>
                                    <div className="carousel-container">
                                        <div className="carousel-content">
                                            <h2 className="animated fadeInDown">
                                                <img src={logo} alt="" className="img-responsive" />
                                            </h2>
                                            <a href="#about" className="btn-get-started animated fadeInUp">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
