import React, { Component } from 'react';
import { Helmet } from 'react-helmet'
import Header from './components/layout/header';
import Banner from './components/layout/banner';
import About from './components/layout/about';
import Events from './components/layout/events'
import Team from './components/layout/teams'
import BottomWidget from './components/layout/bottomWidget'
import Footer from './components/layout/footer'
import './App.css';

class App extends Component {

  componentDidMount() {
	let styles = [
		{ src: '/color/default.css' },
		{ src: '/css/style.css' },
		{ src: '/css/bootstrap.min.css' }
	]
	  
	styles.map(item => {
		const style = document.createElement('link')
		const head = document.head;
		style.rel = 'stylesheet'
		style.type = 'text/css'
		style.href = item.src
		// document.head.appendChild(style)
		head.insertBefore(style, head.childNodes[0])
	})


	let scripts = [
		{ src: '/js/bootstrap.min.js' },
		{ src: '/js/wow.min.js' },
		{ src: '/js/jquery.scrollTo.min.js' },
		{ src: '/js/jquery.nav.js' },
		{ src: '/js/modernizr.custom.js' },
		{ src: '/js/grid.js' },
		{ src: '/js/stellar.js' },
		{ src: '/js/custom.js' }
	]

	scripts.map(item => {
		const script = document.createElement('script')
		script.src = item.src
		script.type = 'text/javascript'
		document.body.appendChild(script)
	})
  }

  render()
  {
	return (
		<div className="App">
			<Helmet>
				<title>Lebnet Canada</title>
				<meta name="description" content="LebNet is a non-profit organization focused on Lebanese professionals living in North America." />
			</Helmet>
			<Header />
			<Banner />
			<About />
			<Events />
			<Team />
			<BottomWidget />
			<Footer />
		</div>
	)
  }
}

export default App;
