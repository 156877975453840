import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class eventItem extends Component {

    render() {
        const { id, title, image } = this.props.event
        // const { index } = this.props.active
        return (
            <>
                <div className={`item ${id == 1 ? 'active' : ''}`}>
                    <div className="row">
                        <div className="col-sm-12 col-md-5">
                            <div className="screenshot  ">
                                <img src={image} alt="" className="img-responsive"/>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-offset-1 col-md-6">
                            <div className=" ">
                                <h4>{title}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
            
eventItem.propTypes = {
    event: PropTypes.object.isRequired                   
}