import React, { Component } from 'react'

export default class teamlist extends Component {
    render() {
        const { name, position, image, social } = this.props.team
        return (
            < div className = "col-lg-4 col-md-4 col-sm-6 col-xs-12 " >
                <div className="box-team wow bounceInUp" data-wow-delay="0.1s">
                    <img src={image} alt="" className="img-circle img-responsive" />
                    <h4>{name}</h4>
                    <p>{position}</p>
                    <p><a href={social.linkedin} target="_blank"><i className="fa fa-linkedin"></i></a></p>
                </div>
            </div>
        )
    }
}
