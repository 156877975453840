import React, { Component } from 'react'
import TeamList from './teamlist'
import team1 from '../../img/team/Fadi-Joseph.jpg'
import team2 from '../../img/team/Fares-Mubarak.jpg'
import team3 from '../../img/team/Frederick-Wardini.jpg'
import team4 from '../../img/team/Raymond-Lahoud.jpg'
import team5 from '../../img/team/Mohammad-Darwish.jpg'
import team6 from '../../img/team/Nour-El-Kadri.jpg'

export default class teams extends Component {
    state = {
        teams: [
            {
                id: '1',
                name: 'Fadi Joseph',
                position: 'President',
                image: team1,
                social: {
                    linkedin: 'https://www.linkedin.com/in/fadijoseph/'
                }
            },
            {
                id: '2',
                name: 'Fares Mubarak',
                position: 'Treasurer',
                image: team2,
                social: {
                    linkedin: 'https://www.linkedin.com/in/faresmubarak/'
                }
            },
            {
                id: '3',
                name: 'Frederick Wardini',
                position: 'Secretary',
                image: team3,
                social: {
                    linkedin: 'https://www.linkedin.com/in/frederickwardini/'
                }
            },
            {
                id: '4',
                name: 'Raymond Lahoud',
                position: 'Board Member and Toronto Community Lead',
                image: team4,
                social: {
                    linkedin: 'https://www.linkedin.com/in/raymondlahoud/'
                }
            },
            {
                id: '5',
                name: 'Mohammad Darwish',
                position: 'Vancouver Community Lead',
                image: team5,
                social: {
                    linkedin: 'https://www.linkedin.com/in/mdarwish/'
                }
            },
            {
                id: '6',
                name: 'Nour El Kadri',
                position: 'Ottawa Community Lead',
                image: team6,
                social: {
                    linkedin: 'https://www.linkedin.com/in/nour-el-kadri-858390/'
                }
            }
        ]
    }

    render() {
        console.log(this.state.teams)
        return (
            <>
                <section id="team" className="home-section bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-offset-2 col-md-8">
                                <div className="section-heading">
                                    <h2>Meet the Team</h2>
                                    <div className="heading-line"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row team-section">
                            {this.state.teams.map((team, index) => 
                                <TeamList
                                    key={index}
                                    team={team}
                                />
                            )}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
