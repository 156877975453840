import React, { Component } from 'react'
import EventItem from './eventItem'
import img1 from '../../img/events/vancouver.jpg'
import img2 from '../../img/events/montreal.jpg'
import img3 from '../../img/events/toronto.jpg'

export default class events extends Component {
    state = {
        events: [
            { id: '1', 'title': 'LebNet Vancouver', 'image': img1  },
            { id: '2', 'title': 'LebNet Montreal', 'image': img2  },
            { id: '3', 'title': 'LebNet Toronto', 'image': img3  }
        ],
        active: '1'
    }

    render() {
        // const { id, title, image } = this.state.events
        console.log(this.state.events)
        // return this.state.events.map((event, index) => <EventItem key={index} event={event} active={index} />)
        return (
            <>
                <section id="services" className="home-section bg-gray">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-offset-2 col-md-8">
                                <div className="section-heading">
                                    <h2>Events</h2>
                                    <div className="heading-line"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div id="carousel-service" className="service carousel slide ltr" data-ride="carousel">
                                    <div className="carousel-inner">
                                        {this.state.events.map((event, index) => 
                                            <EventItem
                                                key={index}
                                                event={event}
                                                active={this.state.active} 
                                            />
                                            
                                        )}
                                        <ol className="carousel-indicators">
                                            {this.state.events.map((event, index) =>
                                                <li
                                                    data-target="#carousel-service"
                                                    data-slide-to={index}
                                                    className={this.state.active === event.id ? 'active' : ''}
                                                    key={index}
                                                >

                                                </li>
                                            )}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
