import React, { Component } from 'react'
import group from '../../img/LebNet-Canada-About Us.jpeg'

export default class about extends Component {
    render() {
        return (
            <>
                <section id="about" className="home-section bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-offset-2 col-md-8">
                                <div className="section-heading">
                                    <h2>Lebnet Canada - About Us</h2>
                                    <div className="heading-line"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row fadeInUp">
                            <div className="col-md-6 about-img">
                                <img src={group} alt=""/>
                            </div>
                            <div className="col-md-6 content">
                                <h3>LebNet Canada is a non-profit organization with a mission to create a diaspora network of Lebanese-Canadian  professionals. The goal of the organization is to provide mentorship, coaching, business and entrepreneurship training as well as networking events and talks.</h3>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
